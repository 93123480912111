
import React, { useState, useEffect } from "react";
import axios from "axios";
import { LOGIN_VERIFY} from  "../constants";
import {useNavigate} from 'react-router-dom'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


function Login () {
    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showerror, setShowError] = useState("");
    const navigate = useNavigate();
    const [deviceName, setDeviceName] = useState("")


    const handleUsernameChange = (event) => {
      setShowError(false)
        setUsername(event.target.value);
      };
    
      const handlePasswordChange = (event) => {
        setShowError(false)
        setPassword(event.target.value);
      };
    
      const handleSubmit = (event) => {
        
        
        event.preventDefault();
        
        axios
          .post(LOGIN_VERIFY, {
            username: username,
            password: password,
            'agent':  deviceName
          },
          {headers: {
            'Content-Type': 'multipart/form-data'
          }}
          )
          .then((erpresponse) => {
            let response = erpresponse.data.response;
            // console.log(response);
            if (response.httpcode === 200) {
              
              sessionStorage.setItem("loggedIn", true);
              sessionStorage.setItem("token", response.response.jwtToken);
              sessionStorage.setItem("username", response.response.user_Id);
              sessionStorage.setItem("display_name", response.response.ename);
              sessionStorage.setItem("userData", JSON.stringify(response.response));
              navigate('/list');
             // window.location.href = "/dashboard";
            } else {
              setShowError(true)
              setError("ErrorCode: "+response.httpcode);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };

      useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        console.log('agent:', navigator.userAgent);
        setDeviceName(navigator.userAgent)
        if (loggedIn) {
          navigate("/dashboard");
        }
      }, [navigate]);

    return (
        <>

    <main className="authentication-content">
      <div className="container-fluid">
        <div className="authentication-card">
          <div className="card shadow rounded-0 overflow-hidden">
            <div className="row g-0">
              <div className="col-lg-12">
                <h1 className="mt-3 text-center ">Payment Collection</h1>
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title">Sign In</h5>
                  <hr />
                  {showerror ? (
                    <div className="alert border-0 bg-danger alert-dismissible fade show py-2">
                    <div className="d-flex align-items-center">
                      <div className="fs-3 text-white"><i className="bi bi-x-circle-fill"></i>
                      </div>
                      <div className="ms-3">
                        <div className="text-white">username or password error!<br /> {error}</div>
                      </div>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                  ) : "" }
                  <form
                    className="form-body"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <div className="row g-3">
                      <div className="col-12">
                        <label for="inputEmailAddress" className="form-label">
                          Username
                        </label>
                        <div className="ms-auto position-relative">
                          <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                            <i className="bi bi-envelope-fill"></i>
                          </div>
                          <input
                            name="username"
                            type="text"                            
                             onChange={handleUsernameChange}                            
                            className="form-control radius-30 ps-5"
                            id="inputEmailAddress"
                            placeholder="enter username"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label
                          for="inputChoosePassword"
                          className="form-label"
                        >
                          Enter Password
                        </label>
                        <div className="ms-auto position-relative">
                          <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                            <i className="bi bi-lock-fill"></i>
                          </div>
                          <input
                            type="password"                            
                            onChange={handlePasswordChange}
                            name="password"                            
                            className="form-control radius-30 ps-5"
                            id="inputChoosePassword"
                            placeholder="Enter Password"
                          />
                          
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-grid">
                          <button 


                            type="submit"
                            className="btn btn-primary radius-30"
                          >
                            Sign In
                          </button>
                        </div>
                        { deviceName }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    
      
    </>)
}

export default Login;