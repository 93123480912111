export const API_TMP_BASE_URL  = "http://172.16.5.2/chequecollection/";
export const API_BASE_URL  = "https://chkapp.mgcc.ae/api/";
export const ERP_API_BASE_LIVE = "http://apps.webhop.biz:2087/api/";
export const ERP_API_BASE_LIVE_DEBUG = "http://apps.webhop.biz:2087/test/api/";

export const API_BASE = API_BASE_URL;

export const LOGIN_VERIFY    = API_BASE + "verify.php";
export const CLIENT_LIST     = API_BASE + "getclients.php";
export const COMPANY_LIST    = API_BASE + "getcompany.php";
export const INVOICES_LIST   = API_BASE + "getinvoices.php";
export const UPDATE_INVOICES = API_BASE + "updateInvoices.php";
export const GET_BANK_LIST   = API_BASE + "getBankList.php";
export const GET_DEPOSIT_BANK_LIST   = API_BASE + "getDepositBankList.php";
export const GET_USER_HISTORY = API_BASE + "getuserhistory.php";
export const GET_INVOICE = API_BASE + "getinvoiceprint.php";
export const REGISTER_CUSTOMER = API_BASE + "registerCustomer.php";
export const SHARE_VIA_EMAIL = API_BASE + "shareviaemail.php";
export const RECEIPT_PAYMENT_TITLE = "Receipt Voucher";

export const CUSTOMER_REG_COMPANY_NAME = '\xD8\xA7\xD8\xB3\xD9\x85\x20\xD8\xA7\xD9\x84\xD8\xB4\xD8\xB1\xD9\x83\xD8\xA9';

export const INVOICE_COMPANY_HEADER = "Al Marwan General Contracting Co.  Post Box: 4174\nTel: +971 6 5345 222\nFax: +971 6 5344337";