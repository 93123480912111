import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import logo from '../images/logo-login.png'
import {
  GET_INVOICE,
  RECEIPT_PAYMENT_TITLE,
  SHARE_VIA_EMAIL,
} from "../constants";
import axios from "axios";
import { useReactToPrint } from "react-to-print";

import { TailSpin, Bars } from "react-loader-spinner";

export const Printpage = (params) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let sessionData = JSON.parse(sessionStorage.getItem("userData"));
  const navigate = useNavigate();
  const toWords = new ToWords({
    localeCode: "en-AE",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });
  const [clientFullName, setClientFullName] = useState("");
  const [clientAccCode, setClientAccCode] = useState("");
  const [clientSubTitle, setClientSubTitle] = useState("");
  const [customerSign, setCustomerSign] = useState("");
  const [error, setError] = useState("");
  const [showerror, setShowError] = useState(false);
  const [companyName, setComopanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPOBox, setCompanyPOBox] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyFax, setCompanyFax] = useState("");
  const [invoiceInfo, setInvoiceInfo] = useState([]);

  const [reciptType, setReciptType] = useState("");
  const [reciptCurrency, setReciptCurrency] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [receiptDated, setReceiptDated] = useState("");
  const [receiptCreditToAcc, setReceiptCreditToAcc] = useState("");
  const [receiptName, setReceiptName] = useState("");
  const [receiptFrom, setReceiptFrom] = useState("");
  const [receiptAEDWords, setReceiptAEDWords] = useState("");
  const [receiptAmount, setReceiptAmount] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [remarksParticular, setRemarksParticular] = useState("");
  const [chequeNo, setChequeNo] = useState("");
  const [chequeDate, setChequeDate] = useState("")

  const [invVRNo, setInvVRNo] = useState("");
  const [invTrcNo, setInvTrcNo] = useState("");
  const [sendEmailTo, setSendEmailTo] = useState("");

  const printRecipt = () => {
    let printContents = document.getElementById("printableInvoice").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const getSelectedInvoice = (vr_no, trc_code) => {
    if (vr_no === "" || trc_code == "") {
      navigate("/userhistory");
    }
    setLoading(true);
    axios
      .post(
        GET_INVOICE,
        {
          userid: sessionData["user_Id"],
          token: sessionData["jwtToken"],
          trc_code: trc_code,
          vr_no: vr_no,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((erpresponse) => {
        //console.log("ERP:", erpresponse)

        let response = erpresponse.data.response;
        setLoading(false);
        if (response.httpcode === 200) {
          setInvoiceInfo(response.response);
          let invoiceInfo = response.response[0];
          setClientFullName(invoiceInfo["acc_name"]);
          setClientAccCode(invoiceInfo["acc_code"]);
          setClientSubTitle(invoiceInfo["notes"]);
          setRemarksParticular(invoiceInfo["particulars"]);

          setComopanyName(invoiceInfo["company_name"]);
          setCompanyAddress("Industrial Area 15.");
          setCompanyPOBox("4174");
          setCompanyPhone("(+971) 6 5345 222");
          setCompanyFax("(+971) 6 534 4337");
          setCustomerSign(invoiceInfo["customer_sign"]);
          setReciptType(RECEIPT_PAYMENT_TITLE);
          setReciptCurrency("AED");
          setReceiptNo(invoiceInfo["vr_no"]);
          let vr_date = invoiceInfo["vr_date"].split("T");
          // setReceiptDated(vr_date[1].substring(0, 6));
          setReceiptDated(
            invoiceInfo["vr_date"].replace("T", " ").substring(0, 19)
          );
          setReceiptCreditToAcc(invoiceInfo["bank_code"]);
          setReceiptName(invoiceInfo["acc_name"]);
          setCustomerSign(invoiceInfo["customer_sign"]);
          setCustomerName(invoiceInfo["Received_From"]);
          setChequeNo(invoiceInfo["chq_no"]);
          setChequeDate(invoiceInfo["chq_date"].replace("T", " ").substring(0, 10));

          // console.log(location);
          let amt =
            invoiceInfo["local_Cur_amt"] !== ""
              ? invoiceInfo["local_Cur_amt"]
              : 0;
          let rcptAmt = isNaN(parseFloat(amt)) ? 0 : parseFloat(amt);
          setReceiptAmount(rcptAmt);
          let words = toWords.convert(rcptAmt);
          setReceiptAEDWords(words);
          //window.location.href = "/dashboard";
        } else {
          setShowError(true);
          setError("ErrorCode: " + response.httpcode);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**** */

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Receipt Voucher",
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });

  /**** */

  const ShareViaEmail = () => {
    Swal.fire({
      title: "Enter Email to share ",
      input: "email",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Send a Copy",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        // try {
        // } catch (error) {
        //   Swal.showValidationMessage(`
        //     Request failed: ${error}
        //   `);
        // }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      
      if (result.isConfirmed) {
        console.log("input",result.value);  
        sendEmail(result.value);
         
        
      }
    });
  };

  const sendEmail = (email) => {
    setLoading(true);
    axios
      .post(
        SHARE_VIA_EMAIL,
        {
          userid: sessionData["user_Id"],
          token: sessionData["jwtToken"],
          trc_code: invTrcNo,
          vr_no: invVRNo,
          email: email,
          amount_in_words: receiptAEDWords,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((erpresponse) => {
        let response = erpresponse.data.response;
        setLoading(false);
        if (response.httpcode === 200) {
          Swal.fire({
            title: "Email Sent",
            text: response.msg,
          });
          return true;
        }
        
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };
  /******** */

  useEffect(() => {
    if (location.state === null || location.state === "") {
      navigate("/userHistory");
    } else {
      console.log("LOC: ", location);
      setInvVRNo(location.state["vr_no"]);
      setInvTrcNo(location.state["trc_code"]);
      getSelectedInvoice(location.state["vr_no"], location.state["trc_code"]);
    }
  }, []);

  return (
    <>
      <div className="">
        <Header />

        <main className="page-content">
          {loading ? (
            <Bars color="red" radius={"8px"} />
          ) : (
            <div className="card border shadow-none">
              <div className="card-header py-3">
                <div className="row align-items-center g-3">
                  <div className="col-12 col-lg-6 text-md-end">
                    <a href="/userhistory" className="btn btn-sm btn-secondary">
                      <i className="bi bi-reply-all-fill"></i> Back to History
                    </a>
                    &nbsp;
                    <a
                      href="#!"
                      onClick={handlePrint}
                      className="btn btn-sm btn-danger"
                    >
                      <i className="bi bi-printer-fill"></i> Print / Download
                    </a>
                    &nbsp;
                    <a
                      href="#!"
                      onClick={ShareViaEmail}
                      className="btn btn-sm btn-success"
                    >
                      <i className="bi bi-envelope"></i> Share{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div
                id="printableInvoice"
                className="pass-title"
                ref={componentRef}
                style={{ width: "100%", height: window.innerHeight }}
              >
                <div className="card-header py-2 bg-light text-center">
                  <div className="row row-cols-1 row-cols-lg-3">
                    <div className="col">
                      <div className="">
                        <img src={logo} alt="logo" />
                        <h2>{companyName} </h2>
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">
                            {companyAddress}
                          </strong>
                          <br />
                          Post Box: {companyPOBox}
                          <br />
                          Tel: {companyPhone}
                          <br />
                          Fax: {companyFax}
                        </address>
                      </div>
                    </div>
                    <hr />
                    <h5 className="text-inverse">{reciptType}</h5>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="text-right "
                    style={{
                      marginTop: "-10px",
                      marginBottom: "15px",
                      float: "right",
                      border: "1px solid #ccc",
                      padding: "5px",
                    }}
                  >
                    <b>{reciptCurrency} :</b> {receiptAmount}
                  </div>
                  <div style={{ content: "", clear: "both" }}></div>
                  <div className="table-responsive">
                    <table className="table table-invoice" border={1}>
                      <thead>
                        {/* <tr>
                        
                        <th colSpan={3}></th>
                        <th>{reciptCurrency}</th>
                        
                      </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          <th width="200px">R. No.</th>
                          <td className="text-left">{receiptNo}</td>

                          <th>Date:</th>
                          <td className="text-left">{receiptDated}</td>
                        </tr>
                        <tr>
                          <th>Cheque No.</th>
                          <td  className="text-left">
                            {chequeNo}
                          </td>
                          <th>Cheque Date.</th>
                          <td  className="text-left">
                            {chequeDate}
                          </td>
                        </tr>
                        <tr>
                          <th>Credit To A/C No.</th>
                          <td className="text-left">{receiptCreditToAcc} </td>
                          <th>Name:</th>
                          <td className="text-left">{receiptName}</td>
                        </tr>
                        <tr>
                          <th>Received From.</th>
                          <td colSpan={4} className="text-left">
                            {clientFullName}
                          </td>
                        </tr>
                        <tr>
                          <th>Amount in words.</th>
                          <td colSpan={4} className="text-left">
                            {receiptAEDWords}
                          </td>
                        </tr>
                        <tr>
                          <th>Remarks/Particular.</th>
                          <td colSpan={4} className="text-left">
                            {remarksParticular}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>&nbsp;</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>&nbsp; </td>
                          <td className="text-center">
                            {/* <h3>Signature</h3><br /> */}

                            <img
                              src={customerSign}
                              style={{ width: "80px" }}
                              alt="Signature"
                            />
                            <hr />
                            {customerName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card-footer py-3">
                  <p className="text-center mb-2">
                    THANK YOU FOR YOUR BUSINESS
                  </p>
                  <p className="text-center d-flex align-items-center gap-3 justify-content-center mb-0">
                    “This document is computer generated and does not require
                    the Registrar’s signature or the Company’s stamp in order to
                    be considered valid”.
                    {/* <span className="">
                  <i className="bi bi-globe"></i> www.almarwangroup.com
                </span>
                <span className="">
                  <i className="bi bi-telephone-fill"></i> T:+1971.6.5345222
                </span>
                <span className="">
                  <i className="bi bi-envelope-fill"></i> info@almarwangroup.com
                </span> */}
                  </p>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};
