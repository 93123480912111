import React, { useState, useEffect } from "react";
import Header from "./Header";
import { CUSTOMER_REG_COMPANY_NAME, REGISTER_CUSTOMER } from "../constants";
import axios from "axios";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import $, { post } from "jquery";

export const CustomerRegistration = () => {
  const [pageTitle, setPageTitle] = useState("Customer Registion");
  const [companyName, setCompanyName] = useState("");
  const [companyNameAr, setCompanyNameAr] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [licenseIssueDate, setLicenseIssueDate] = useState("");
  const [licenseExpiryDate, setLicenseExpiryDate] = useState("");
  const [sponsorName, setSponsorName] = useState("");
  const [natureOfBusiness, setNatureOfBusiness] = useState("");
  const [vatRegNo, setVatRegNo] = useState("");
  const [vatIssueDate, setVatIssueDate] = useState("");
  const [vatExpiryDate, setVatExpiryDate] = useState("");
  const [building, setBuilding] = useState("");
  const [plotNo, setPlotNo] = useState("");
  const [streetNo1, setStreetNo1] = useState("");
  const [streetNo2, setStreetNo2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [pobox, setPobox] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [contactOfficeNo, setContactOfficeNo] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactWebsite, setContactWebsite] = useState("");
  const [contactCountry, setContactCountry] = useState("");

  const [licenseFile, setLicenseFile] = useState("");
  const [vatRegFile, setVatRegFile] = useState("");

  const  handleLicenseFileInputChange = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0] === "") return "";

    let file = e.target.files[0];
    let reader = new FileReader();

    if (file === "" || file === undefined) {
      return false;
    }

    reader.readAsDataURL(file);

    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader.result);
      setLicenseFile(reader.result);      
    };  
  };
  
  const  handleVatRegFileInputChange = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0] === "") return "";

    let file = e.target.files[0];
    let reader = new FileReader();

    if (file === "" || file === undefined) {
      return false;
    }

    reader.readAsDataURL(file);

    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader.result);
      setVatRegFile(reader.result);      
    };  
  };

  const registerCustomer = async () => {
    let registerInfo = [];
    if (companyName.trim().length < 3) {
      Swal.fire("Please enter company name minimum 3 characters.");
      
      return false;
    }
    if (companyNameAr.trim().length < 3) {
      Swal.fire("Please enter company name in arabic minimum 3 characters");
      
      return false;
    }
    if (licenseNo.trim().length < 6) {
      Swal.fire("Please enter minimum 6 character.? ");
      
      return false;
    }
    if (licenseIssueDate.length < 10) {
      Swal.fire("Please enter license issue date.? ");
      
      return false;
    }
    if (licenseExpiryDate.length < 10) {
      Swal.fire("Please enter license expiry date.? ");
      
      return false;
    }
    if (vatRegNo.trim().length !== 9) {
      Swal.fire("Please enter correct VAT Reg No.? ");
      
      return false;
    }
    
    if (vatIssueDate === "") {
      Swal.fire("Please enter correct VAT Issue Date.? ");
      
      return false;
    }
    if (vatExpiryDate === 10) {
      Swal.fire("Please enter correct VAT Expiry Date.? ");
      
      return false;
    }

    registerInfo["company_name"] = companyName;
    registerInfo["company_name_ar"] = companyNameAr;
    registerInfo["license_no"] = licenseNo;
    registerInfo["license_issue_date"] = licenseIssueDate;
    registerInfo["license_expiry_date"] = licenseExpiryDate;
    registerInfo["sponsor_name"] = sponsorName;
    registerInfo["natureOfBusiness"] = natureOfBusiness;
    registerInfo["vatRegNo"] = vatRegNo;
    registerInfo["vatIssueDate"] = vatIssueDate;
    registerInfo["vatExpiryDate"] = vatExpiryDate;
    registerInfo["building"] = building;
    registerInfo["plotNo"] = plotNo;
    registerInfo["streetNo1"] = streetNo1;
    registerInfo["streetNo2"] = streetNo2;
    registerInfo["city"] = city;
    registerInfo["country"] = country;
    registerInfo["pobox"] = pobox;
    registerInfo["contactPerson"] = contactPerson;
    registerInfo["contactMobile"] = contactMobile;
    registerInfo["contactOfficeNo"] = contactOfficeNo;
    registerInfo["contactEmail"] = contactEmail;
    registerInfo["contactWebsite"] = contactWebsite;
    registerInfo["contactCountry"] = contactCountry;
    registerInfo["vat_reg_attachment"] = vatRegFile;
    registerInfo["trade_lic_attachment"] = licenseFile;

    let postData = [];
    postData.push({data: registerInfo});
    let postString = JSON.stringify(postData);

    console.log("logs:", postString);
    console.log("registerInfo:", registerInfo);
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": 'multipart/form-data',
      },
      mode: "cors",
    };
    // return false;;
    const result = await axios.post(REGISTER_CUSTOMER, postString, customConfig);
    let savedResponse = result.data.response;

    if (
      savedResponse.httpcode === 200 &&
      savedResponse.response["acc_code"] !== 0 &&
      savedResponse.response["acc_code"] !== ""
    ) {
      Swal.fire({
        title: "Success",
        icon: "success",
        html: "Your request is register successfully. Reference No.#" + savedResponse.response["acc_code"],
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Finish",
        // denyButtonText: `Don't save`
      });
    } else {
      Swal.fire({
        title: "Error: " + savedResponse.httpcode,
        text:
          "Something went wrong! -> " +
          savedResponse.response["payment"]["particulars"],
      });
    }

  };

  $(document).on("keyup", ".decimalInput", function () {
    var val = this.value;
    var re = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/g;
    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?|[0-9]+)/g;
    if (re.test(val)) {
    } else {
      val = re1.exec(val);
      if (val) {
        this.value = val[0];
      } else {
        this.value = "";
      }
    }

    
    
  });

  useEffect(() => {

  }, []);

  return (
    <>
      <Header />
      <main className="page-content">
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={registerCustomer} method="POST">
            <div className="carded">
              <div className="p-2 border-bottom m-2">
                <h5 className="h5 mb-0">{pageTitle}</h5>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="p-2 border-bottom m-2">
                      <h5 className="h5 mb-0">Company Info</h5>
                    </div>
                    <div className="col-md-6">
                      <label>Company Name <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span> </label>
                      <input
                        className="form-control form-control-lg mb-3"
                        type="text"
                        name="company_name"
                        id="company_name"
                        autoFocus 
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required={true}
                        placeholder="Company Name"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Company name (Arabic) <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span></label>
                      <input
                        dir="rtl"
                        id="company_name_ar"
                        name="company_name_ar"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        value={companyNameAr}
                        onChange={(e) => setCompanyNameAr(e.target.value)}
                        required={true}
                        placeholder="Company name (Arabic)"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Lincense No <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span></label>
                      <input
                        className="form-control form-control-lg mb-3"
                        type="text"
                        required={true}
                        onChange={(e) => setLicenseNo(e.target.value)}
                        value={licenseNo}
                        id="license_no"
                        name="license_no"
                        placeholder="Lincense No"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Lincense File <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span></label>
                      <input
                        className="form-control form-control-lg mb-3"
                        type="file"
                        required={true}
                        id="license_file"
                        accept=".jpef, .png, .jpg, .JPEF, .JPEG, .PNG, .JPG"
                        onChange={handleLicenseFileInputChange}
                        name="license_file"
                        placeholder="Lincense File"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Lincense Issue Date <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span></label>
                      <div><DatePicker
                        className="form-control form-control-lg mb-3 "
                        dateFormat="dd/MM/yyyy"                        
                        selected={licenseIssueDate}
                        minDate={
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() - 11,
                            1
                          )
                        }
                        name="license_issue_date"
                        id="license_issue_date"
                        onChange={(date) => setLicenseIssueDate(date)}
                        placeholderText="dd/MM/yyyy"
                      />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Lincense Expiry Date <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span> </label>
                      <div><DatePicker
                        className="form-control form-control-lg mb-3 "
                        dateFormat="dd/MM/yyyy"                        
                        selected={licenseExpiryDate}
                        minDate={
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() - 11,
                            1
                          )
                        }
                        placeholderText="dd/MM/yyyy"
                        name="license_expiry_date"
                        id="license_expiry_date"
                        onChange={(date) => setLicenseExpiryDate(date)}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="p-2 border-bottom m-2">
                      <h5 className="h5 mb-0">Sponosr &amp; VAT Info</h5>
                    </div>
                    <div className="col-md-6">
                      <label>Sponsor Name <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span></label>
                      <input
                        className="form-control form-control-lg mb-3"
                        type="text"
                        value={sponsorName}
                        onChange={(e) => setSponsorName(e.target.value)}
                        id="sponsor_name"
                        required={true}
                        name="sponsor_name"
                        placeholder="Sponsor Name"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Nature of the business</label>
                      <input
                        className="form-control form-control-lg mb-3"
                        type="text"
                        value={natureOfBusiness}
                        onChange={(e) => setNatureOfBusiness(e.target.value)}
                        id="nature_of_business"
                        name="nature_of_business"
                        placeholder=" Nature of the business"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>VAT Reg No</label>
                      <input
                        className="form-control form-control-lg mb-3"
                        type="text"
                        value={vatRegNo}
                        onChange={(e) => setVatRegNo(e.target.value)}
                        name="vat_reg_no"
                        id="vat_reg_no"
                        placeholder="VAT Reg No"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>VAT File</label>
                      <input
                        className="form-control form-control-lg mb-3"
                        type="file"
                        name="vat_file"
                        id="vat_file"
                        accept=".jpef, .png, .jpg, .JPEF, .JPEG, .PNG, .JPG"
                        onChange={handleVatRegFileInputChange}
                        placeholder="Lincense File"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>VAT Issue Date</label>
                      <div><DatePicker
                        className="form-control form-control-lg mb-3 "
                        dateFormat="dd/MM/yyyy"                        
                        selected={vatIssueDate}
                        minDate={
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() - 11,
                            1
                          )
                        }
                        placeholderText="dd/MM/yyyy"
                        name="vat_issue_date"
                        id="vat_issue_date"
                        onChange={(date) => setVatIssueDate(date)}
                      />
                     </div>
                    </div>
                    <div className="col-md-6">
                      <label>VAT Expiry Date</label>
                      <div>
                      <DatePicker
                        className="form-control form-control-lg mb-3 "
                        dateFormat="dd/MM/yyyy"                        
                        selected={vatExpiryDate}
                        minDate={
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() - 11,
                            1
                          )
                        }
                        placeholderText="dd/MM/yyyy"
                        name="vat_issue_date"
                        id="vat_issue_date"
                        onChange={(date) => setVatExpiryDate(date)}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="p-2 border-bottom m-2">
                      <h5 className="h5 mb-0">Address Info</h5>
                    </div>
                    <div className="col-md-4">
                      <label>Building</label>
                      <input
                        name="building"
                        id="building"
                        value={building}
                        onChange={(e) => setBuilding(e.target.value)}
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="Building"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Plot No</label>
                      <input
                        value={plotNo}
                        onChange={(e) => setPlotNo(e.target.value)}
                        className="form-control form-control-lg mb-3"
                        type="text"
                        name="plot_no"
                        id="plot_no"
                        placeholder=" Plot No"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Street 1</label>
                      <input
                        value={streetNo1}
                        onChange={(e) => setStreetNo1(e.target.value)}
                        className="form-control form-control-lg mb-3"
                        type="text"
                        name="street_no_1"
                        id="street_no_1"
                        placeholder="Street 1"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Street 2</label>
                      <input
                        value={streetNo2}
                        onChange={(e) => setStreetNo2(e.target.value)}
                        name="street_no_2"
                        id="street_no_2"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="Street 1"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label>City</label>
                      <input
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        name="city"
                        id="city"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="City"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Country</label>
                      <input
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className="form-control form-control-lg mb-3"
                        type="text"
                        name="country"
                        id="country"
                        placeholder="Country"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>P.O.BOX No</label>
                      <input
                        value={pobox}
                        onChange={(e) => setPobox(e.target.value)}
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="P.O.BOX No"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="p-2 border-bottom m-2">
                      <h5 className="h5 mb-0">Contact Person Info</h5>
                    </div>
                    <div className="col-md-4">
                      <label>Contact Person <span style={{ color: "#ff0000",fontSize: "16px" }}>*</span></label>
                      <input
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        name="contact_person"
                        id="contact_person"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="Contact Person"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Mobile No</label>
                      <input
                        value={contactMobile}
                        onChange={(e) => setContactMobile(e.target.value)}
                        name="mobile_no"
                        id="mobile_no"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder=" Mobile No"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Office No.</label>
                      <input
                        value={contactOfficeNo}
                        onChange={(e) => setContactOfficeNo(e.target.value)}
                        name="office_no"
                        id="office_no"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="Office No."
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Email</label>
                      <input
                        name="email"
                        id="email"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="Email"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label>website</label>
                      <input
                        value={contactWebsite}
                        onChange={(e) => setContactWebsite(e.target.value)}
                        name="website"
                        id="website"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="website"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Country</label>
                      <input
                        value={contactCountry}
                        onChange={(e) => setContactCountry(e.target.value)}
                        name="contact_country"
                        id="contact_country"
                        className="form-control form-control-lg mb-3"
                        type="text"
                        placeholder="Country"
                        aria-label=".form-control-lg example"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <input
                    onClick={registerCustomer}
                    type="submit"
                    className="btn btn-primary"
                    value={"Save customer >>"}
                  />
                </div>
              </div>
            </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};
