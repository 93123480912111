import React, { useEffect, useState } from "react";
import Header from "./Header";
import { GET_USER_HISTORY } from "../constants";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { Printpage } from "./Printpage";
import { Link, useNavigate } from "react-router-dom";
import { TailSpin, Bars } from "react-loader-spinner";

export const HistoryScreen = () => {

    let sessionUser = JSON.parse(sessionStorage.getItem("userData"));
    const [userid, setUserid] = useState("");
    const [token, setToken] = useState("");
    const [userlogs, setUserlogs] = useState([])
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");
    const [dataLoading, setDataLoading] = useState(false)

    const navigate = useNavigate();

    const getUserLogsHistory = () => {
        setDataLoading(true)
        axios
      .post(
        GET_USER_HISTORY + "?r=" + Math.random(), 
        {
          _userid: sessionUser["user_Id"],    
          _token: sessionUser["jwtToken"],
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((erpresponse) => {
        let dataResponse = erpresponse.data.response;
        setDataLoading(false)
        
        if (dataResponse.httpcode === 200) {
         let response = dataResponse.response;             
          setUserlogs(response);
        } else {
          setShowError(true);
          setError("ErrorCode: " + dataResponse.httpcode);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }

    const showInvoicePage = ( invoice ) => {
        console.log(invoice)
       
    }

      useEffect(() => {
        // let sessionUser = JSON.parse(sessionStorage.getItem("userData"));
        setUserid(sessionUser["user_Id"]);
        setToken(sessionUser["jwtToken"]);
        // setShowError(false);
        getUserLogsHistory()
        setShowError(false);
      },[]);

  return (
    <>
      <Header />
      <main className="page-content">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="p-2 border-bottom m-2">
                <h5 className="h5 mb-0">History</h5>
              </div>
              <div className="card-body">
              {showError ? (
                <div className="alert border-0 bg-danger alert-dismissible fade show py-2">
                  <div className="d-flex align-items-center">
                    <div className="fs-3 text-white">
                      <i className="bi bi-x-circle-fill"></i>
                    </div>
                    <div className="ms-3">
                      <div className="text-white">
                        Something Went Wrong!
                        <br /> {error}
                      </div>
                    </div>
                  </div>
                  
                </div>
              ) : (
                ""
              )}
              
                <table className="table table-striped table-bordered" id="">
                  <thead>
                    <tr>
                      <th>VR No#.</th>
                      <th>Account</th>
                      <th>Client</th>
                      <th>Company</th>
                      <th>Dated</th>
                      <th>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataLoading ? (
                    <tr>
                        <td colSpan={6} className="text-center"><h4 style={{ color: "red" }}><Bars wrapperClass="text-center" width={"42px"} /></h4>
                        
                        </td>
                    </tr>
                    ): (
                      userlogs.length !== 0 ? (
                  userlogs.map((client, idx) => (  
                    
                    <tr>{console.log(client)}
                      <td>{client['vr_no']}</td>
                      <td>{client['acc_code']}</td>
                      <td>{client['acc_name']}</td>
                      <td>{client['company_name']}</td>
                      <td>{client['vr_date'].substring(0,10)}</td>
                      <td>
                        <div className="text-center">
                        <Link to={{ pathname: "/printhistory" }} state={client}><i className="bi bi-eye-fill"></i></Link>                        
                        </div>
                      </td>
                    </tr>
                     ) )   
                     ) : (
                      <tr>
                      <td colSpan={6} className="text-center"><h4 style={{ color: "green" }}>No History Found</h4>
                      
                      </td>
                  </tr>
                  ) )
                    }  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
