import React, { useEffect, useState } from "react";
import Header from "./Header";
import { CLIENT_LIST } from "../constants";
import axios from "axios";
import Swal from "sweetalert2";  
import $ from 'jquery';
import { useNavigate } from "react-router-dom";

const ClientList = () => {
  const [showerror, setShowError] = useState(false);
  const [token, setToken] = useState("");
  const [userid, setUserid] = useState("");
  const [userData, setUserData] = useState("");
  const [error, setError] = useState("");
  const [myclistlist, setMyclientlist] = useState([])
  let sessionData = JSON.parse(sessionStorage.getItem("userData"));
  const navigate = useNavigate();
  sessionStorage.removeItem("paymentmode") ;
  sessionStorage.removeItem("chkAmount") ;
  
  const handlePaymentButton = (clientid) => {
    
   
   sessionStorage.removeItem("selectedClient") ;

    var gotonext = false;
    for(var client in myclistlist ) {
      if (clientid === myclistlist[client].acc_code) {
        sessionStorage.setItem("selectedClient",  JSON.stringify(myclistlist[client]));    
        gotonext = true;
      }
    }
    if (gotonext) {
      window.location.href = "/payment/"+clientid;
    } else {
      Swal.fire({
        title: "Error",
        text: "Something went wrong! tra agian later.",
        icon: "error",
        
    })
    }
  };

  
  const handleReturnToOffice = (clientid) => {

  }

  const getclientlist = () => {
    axios
      .post(
        CLIENT_LIST,
        {
          userid: sessionData["user_Id"],
          token: sessionData["jwtToken"],
        }
        ,
           {headers: {
             'Content-Type': 'multipart/form-data'
           }}
      )
      .then((erpresponse) => {
        //console.log("ERP:", erpresponse)

        let response = erpresponse.data.response;

        if (response.httpcode === 200) {
            setMyclientlist(response.response);
          //window.location.href = "/dashboard";
        } else {
          setShowError(true);
          setError("ErrorCode: " + response.httpcode);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let useredData = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(useredData);

    setUserid(useredData["user_Id"]);
    setToken(useredData["jwtToken"]);
    sessionStorage.removeItem("selectedClient") ;
    getclientlist();
    
  }, []);

  const returnToOffice = (ref) => {
    Swal.fire({
        title: "Alert",
        text: "Are you sure you want this return to Office?",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!"
    }).then((result) => {

        if (result.isConfirmed) {
            $('#clientrow_' + ref).removeClass('d-flex').hide();
            Swal.fire({
                title: "Done!",
                text: "Your return item request has been created.",
                icon: "success"
            });

        }

    });
}

const handlAssignToEmployee = (ref)  => {

    Swal.fire({

        showCancelButton: true,
        confirmButtonText: "Assign!",
        type: "warning",
        title: "Assign to Other",
        html: ` select form List: <select name="assign" id="assign">
            <option value="Qalandar">Qalandar</option>
            <option value="Shahid">Shahid</option>
            <option value="Ammar">Ammar</option>
            
            </select>`,



    }).then((result) => {

        if (result.isConfirmed) {
            //$('#clientrow_' + ref).removeClass('d-flex').hide();
            Swal.fire({
                title: "Assigned!",
                text: "client is assigned to the user.",
                icon: "success"
            });

        }

    });
}

  return (
    <>
      <Header />
      <main className="page-content">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="p-2 border-bottom m-2">
                <h5 className="h5 mb-0">Customers</h5>
              </div>
              {showerror ? (
                <div className="alert border-0 bg-danger alert-dismissible fade show py-2">
                  <div className="d-flex align-items-center">
                    <div className="fs-3 text-white">
                      <i className="bi bi-x-circle-fill"></i>
                    </div>
                    <div className="ms-3">
                      <div className="text-white">
                        Something Went Wrong!
                        <br /> {error}
                      </div>
                    </div>
                  </div>
                  
                </div>
              ) : (
                myclistlist.length === 0 ? (
                  
                  <h5 style={{ color: "red" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                  </svg>&nbsp;No customer found!</h5>
                ) : ("")

              )}
              
              <ul className="list-group">
                {myclistlist.map((clients, idx) => (
                
                <li 
                  id={"clientrow_"+clients.acc_code}
                  
                  className="list-group-item d-flex justify-content-between align-items-start "
                > 
                  <div className="ms-2 me-auto " style={{ cursor: "pointer" }}
                  onClick={() => handlePaymentButton(clients.acc_code) }
                    fullwidth
                  >
                    <div className="fw-bold"> {clients.acc_name} </div>
                    <b>Collection Date:</b> {clients.collection_Date.substring(0, 10).replace("T", " ")}<br />
                    {clients.notes}
                    
                  </div>
                  <div  className="table-actions d-flex align-items-center gap-3 fs-6">
                  
                    <a
                      href="#"
                      className="text-primary redirect"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="View"
                      onClick={() => handlePaymentButton(clients.acc_code) }
                    >
                      <i className="bi bi-eye-fill"></i>
                    </a>
                    
                    {/* <a
                      href="#"
                      className="text-success"
                      onClick={ () => handlAssignToEmployee(clients.acc_code) }
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="assign"
                    >
                      <i className="bx bx-user-circle"></i>
                    </a> */}
                    {/* <a
                      href="#"
                      className="text-danger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      onClick={ () => returnToOffice(clients.acc_code)}
                      title="Return"
                    >
                      <i className="bi bi-reply-all-fill"></i>
                    </a> */}
                  </div> 
                  
                    
                </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ClientList;
