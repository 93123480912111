import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Paymentinfo from "./Paymentinfo";
import axios from "axios";
import SignaturePad from "react-signature-canvas";
import $ from "jquery";
import Swal from "sweetalert2";
import { UPDATE_INVOICES } from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { ToWords } from "to-words";
import { TailSpin, Bars } from "react-loader-spinner";
const Paymentscreen = (props) => {
  const [loading, setLoading] = useState(false);
  const toWords = new ToWords({
    localeCode: "en-AE",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });
  const routeParams = useParams();
  const sigCanvas = useRef({});
  const [sign, setSign] = useState("");
  const [signurl, setSignurl] = useState("");
  const [finalJSON, setFinalJSON] = useState("");
  const [paymentmode, setPaymentmode] = useState("");
  const [totalPaymentAmount, setTotalPaymentAmount] = useState("0");

  let sessionData = JSON.parse(sessionStorage.getItem("userData"));
  let selectedClient = JSON.parse(sessionStorage.getItem("selectedClient"));

  let chkAmount = sessionStorage.getItem("chkAmount");
  let processPaymentMode = sessionStorage.getItem("paymentmode");

  const [isSigned, setIsSigned] = useState(false);
  const [btnSubmitPayment, setBtnSubmitPayment] = useState(false);

  let paymentmodeVal = $("#payment_mode").val();
  let totalAmount = $("#chequeAmount").val();
  console.log("tp", totalAmount);
  const handleSigntureClear = () => {
    setIsSigned(false);
    sign.clear();
    setSignurl("");
    setSign("");
  };

  const handleSigntureSave = async () => {
    let useredData = JSON.parse(sessionStorage.getItem("userData"));
    let chkImage = sessionStorage.getItem("chkimg");
    setLoading(true)

    if (sign.isEmpty()) {
      Swal.fire({
        title: "SignError",
        text: "Please add your signatures.",
      });
      return false;
    }

    setSignurl(sign.getTrimmedCanvas().toDataURL("image/png"));

    let signImage = sign.getTrimmedCanvas().toDataURL("image/png");
    signImage = signImage ? signImage : "";
    var invoices = JSON.parse(sessionStorage.getItem("invoices"));
    setBtnSubmitPayment(true);

    /**
     * Manage Cheque Info
     */
    let payment_info = invoices["payment"];
    let adv_payment_info = invoices["ADV"][0];
    // console.log("29LINE: ",payment_info);

    let remarks = $("#remarks").val();
    let payeeName = "";
    let chequeNo = "";
    let chequeDate = "";
    let chequeAmount = "";
    let bankcode = "";
    let companyCode = "";
    let chequeDepositBank = "";

    let paymentmode = $("#payment_mode").val();
    companyCode = $("#company").val();
    chequeDepositBank = $("#deposit_bankcode").val();
    chequeAmount = $("#chequeAmount").val();
    let customer_Name = $("#signer_name").val();
    let customer_Contact = $("#signer_contact").val();

    if ($.trim(customer_Name) === "") {
      Swal.fire({
        icon: "warning",
        text: "Please enter signed by Full Name?",
      });
      setBtnSubmitPayment(false);
      return false;
    }

    if ($.trim(customer_Contact) === "") {
      Swal.fire({
        icon: "warning",
        text: "Please enter signed by mobile?",
      });
      setBtnSubmitPayment(false);
      return false;
    }
    bankcode = $("#bankcode").val();

    if (paymentmode === "CHEQUE") {
      payeeName = $("#payeeName").val();
      chequeNo = $("#chequeNo").val();
      chequeDate = $("#chequeDate").val();

      var billingdt = chequeDate.split("/");
      chequeDate =
        billingdt[2] + "-" + billingdt[1] + "-" + billingdt[0] + "T00:00:00";

      payment_info["payeE_NAME"] = payeeName;
      payment_info["bill_no"] = chequeNo;
      payment_info["bill_date"] = chequeDate;
      payment_info["cheque_bank"] = bankcode;
      payment_info["trc_code"] = "002";
    } else {
      payment_info["trc_code"] = "004";
      payment_info["payeE_NAME"] = "";
      payment_info["bill_no"] = "";
      payment_info["bill_date"] = "0001-01-01T00:00:00";
      payment_info["cheque_bank"] = "";
    }

    console.log("signurl: ", signImage);
    payment_info["sales_man"] = useredData["user_Id"];
    payment_info["customer_Sign"] = signImage;
    payment_info["cheque_Img"] = chkImage;
    payment_info["acc_code"] = selectedClient["acc_code"];
    payment_info["ref_vr_no"] = parseFloat(selectedClient["ref_no"]);
    payment_info["particulars"] = remarks;
    payment_info["fy_code"] = companyCode;
    payment_info["bank_code"] = chequeDepositBank;
    payment_info["customer_Name"] = customer_Name;
    payment_info["customer_Contact"] = customer_Contact;

    /**
     * Advance Payment
     */

    var advance_payment = $("#advance-payment").val();
    if (advance_payment > 0) {
      /**
       * changed node from: " amt "  to  " paidAmount "
       * as requested
       */
      adv_payment_info["paidAmount"] = parseFloat(advance_payment);
      adv_payment_info["isAdvance"] = true;
    }

    /**
     * Invoices,
     */
    var selected_company = $("#company").val();
    var cmpInvoices = [];
    for (var key in invoices["RECV"]) {
      if (selected_company === key) {
        let rows = invoices["RECV"][key];
        cmpInvoices = rows;
        // return;
      }
    }

    var updateInvoices = [];
    let totalAmt = 0;
    cmpInvoices.forEach((invoice, idx) => {
      let invoiceAmount = parseFloat($( "#invoice-" + selected_company + "_" + idx + 1      ).val());
      invoiceAmount =        (invoiceAmount === "" || invoiceAmount < 0 || isNaN(invoiceAmount)  ) ? 0 : invoiceAmount;      
      invoice["paidAmount"] = parseFloat(invoiceAmount);
      totalAmt = parseFloat(invoiceAmount) + totalAmt;
      // invoice["paidAmount"] = parseFloat(0);
        console.log("C:", invoice["paidAmount"], totalAmt)
      updateInvoices.push(invoice);
    });

    /**
     * both cases added
     */
    totalAmt = totalAmt + adv_payment_info["paidAmount"];
    console.log(totalAmt, parseFloat(totalAmt) , "parseFloat(totalAmt)", adv_payment_info["paidAmount"]); 
    payment_info["acc_amt"] = parseFloat(totalAmt);
    payment_info["local_cur_amt"] = parseFloat(totalAmt);

    updateInvoices.push(adv_payment_info);
    const fJSON = [];
    let words = toWords.convert(parseFloat(totalAmt));
    selectedClient['amount_in_words'] = words;
    fJSON["payment"] = payment_info;
    fJSON["outstanding"] = updateInvoices;
    fJSON["clientinfo"] = selectedClient;
    // console.log("FJSON: ",fJSON)
    // return false;
    setFinalJSON(fJSON);

    
    fJSON.push({ payment: payment_info });
    fJSON.push({ outstanding: updateInvoices });
    fJSON.push({ clientinfo: selectedClient });

    const jsonStr = JSON.stringify(fJSON);
    // console.log("datadata:", fJSON);
// return ;
    // console.log("jsonStr:", jsonStr);
    // return false;

    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": 'multipart/form-data',
      },
      mode: "cors",
    };
    // setTimeout(setLoading(false), 5000); 
    // return false;
    const result = await axios.post(UPDATE_INVOICES, jsonStr, customConfig);

    let savedResponse = result.data.response;
    // console.log("savedResponse:", savedResponse.response["payment"]["vr_no"]);
    setLoading(false);
    if (savedResponse.httpcode === 500) {
      Swal.fire({
        title: "Error: " + savedResponse.httpcode,
        text:
          "Something went wrong! -> " +savedResponse.httpcode,
      });
      return false;
    }

    
    if (
      savedResponse.httpcode === 200 &&
      savedResponse.response["payment"]["vr_no"] !== 0 &&
      savedResponse.response["payment"]["vr_no"] !== ""
    ) {
      Swal.fire({
        title: "Payment Success",
        html: "Reference No.#" + savedResponse.response["payment"]["vr_no"],
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Finish",
        // denyButtonText: `Don't save`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          sessionStorage.removeItem("invoices");
          sessionStorage.setItem("invoices", JSON.stringify(savedResponse));
          sessionStorage.removeItem("chkimg");

          window.location.replace("/completed");
          // navigate("/completed");
        }
      });
    } else {
      Swal.fire({
        title: "Error: " + savedResponse.httpcode,
        text:
          "Something went wrong! -> " +
          savedResponse.response["payment"]["particulars"],
      });
    }
    // console.log("JSONRECEIVED:", result);
  };

  const handlePageAction = () => {
    $("#payment-tab").show();
    $("#complete-tab").hide();
    $("#signature-tab").hide();
  };

  console.log(signurl);

  const updatePaymentBox = () => {
    let paymentmode = $("#payment_mode").val();
    let chequeAmount = $("#chequeAmount").val();
    console.log("P255", paymentmodeVal, "chk:", chkAmount);
  };

  useEffect(() => {
    // setPaymentmode(paymentmode);
    // setTotalPaymentAmount(totalAmount);
    console.log("ACC:", selectedClient["acc_code"]);
  }, [Paymentscreen]);

  return (
    <>
      <Header />
      <main className="page-content">
        <div className="card">
          <div className="card-body">
            <div id="payment-tab">
              <Paymentinfo />
            </div>
            <div id="signature-tab" style={{ display: "none" }}>
            {loading ? (
          <Bars color="red" radius={"8px"} />  
        ) : ( <>
              <div className="">
                <h2>
                  <button
                    onClick={handlePageAction}
                    className="btn btn-sm btn-default btn-primary"
                  >
                    <i className="fa fa-reply-all "></i> Back &nbsp;
                  </button>
                  &nbsp;Signature here
                </h2>
              </div>
              <hr />
              
              <div className="card">
                <div className="card-body">
                  <fieldset>
                    <legend>Signed By</legend>
                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          <b>Full Name:</b>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="signer_name"
                          placeholder="Enter signed by who?"
                        />
                        <br />
                      </div>
                      <div className="col-md-6">
                        <label>
                          <b>Mobile No.:</b>{" "}
                        </label>
                        <input
                          type="text"
                          className="onlydigits form-control"
                          maxLength={13}
                          id="signer_contact"
                          placeholder="0501234567"
                        />
                        <br />
                      </div>
                      <div className="col-md-1">&nbsp;</div>
                      <div className="col-md-5">
                        <div
                          className="row"
                          style={{
                            border: "1px dashed #cccccc",
                            padding: "5px",
                          }}
                        >
                          <div className="col-md-6">
                            <label>
                              <b>Payment Mode</b>{" "}
                            </label>
                          </div>
                          <div className="col-md-6" id="processPaymentMode">
                            {processPaymentMode}
                          </div>
                          <div className="col-md-6">
                            <label>
                              <b>Total Payment:</b>{" "}
                            </label>
                          </div>
                          <div className="col-md-6" id="chkPaymentAmount">
                            {chkAmount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row"></div>
                    <div></div>
                    <div></div>
                  </fieldset>
                  <div
                    className="text-center"
                    style={{ border: "1px solid #ccc", width: 500 }}
                  >
                    <SignaturePad
                      ref={(data) => setSign(data)}
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "sigCanvas",
                      }}
                    />
                    <br />
                    &nbsp;
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "15px" }} className="text-left">
                <button
                  className="btn btn-warning btn-sm"
                  onClick={handleSigntureClear}
                >
                  Clear
                </button>
                &nbsp;
                <button
                  id="btn-submit-payment"
                  disabled={btnSubmitPayment ? false : false}
                  className="btn btn-success  btn-sm"
                  onClick={handleSigntureSave}
                >
                  Submit &raquo;
                </button>
              </div>
              </>
              )}
            </div>
            <div id="complete-tab" style={{ display: "none" }}></div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Paymentscreen;
