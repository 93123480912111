import React, { useEffect, useState } from "react";
import { GET_BANK_LIST, INVOICES_LIST, GET_DEPOSIT_BANK_LIST } from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import subDays from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";

import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';


import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { parseJSON } from "date-fns";

const Paymentinfo = () => {
  const routeParams = useParams();
 const navigate = useNavigate();

  let sessionData = JSON.parse(sessionStorage.getItem("userData"));
  let selectedClient = JSON.parse(sessionStorage.getItem("selectedClient"));

  const [showerror, setShowError] = useState(false);
  const [token, setToken] = useState("");
  const [userid, setUserid] = useState("");
  const [userData, setUserData] = useState("");
  const [error, setError] = useState("");
  const [mycompanylist, setMycompanylist] = useState([]);

  const [clientFullName, setClientFullName] = useState("");
  const [clientAccCode, setClientAccCode] = useState("");
  const [clientSubTitle, setClientSubTitle] = useState("");

  const [paymentaction, setPaymentaction] = useState("RECV");
  const [paymentmode, setPaymentmode] = useState("CASH");
  const [companydd, setCompanydd] = useState("");
  const [invoicesList, setInvoicesList] = useState([]);
  const [showInvoices, setShowInvoices] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [depostiBankList, setDepositBankList] = useState([]);

  const [isDisabled, setIsDisabled] = useState(true);

  //   const [invoicePaymentmode, setInvoicePaymentmode] = useState(false)

  const [clientid, setClientid] = useState("");

  const [tabCashRecv, setTabCashRecv] = useState(false);
  const [tabCashAdv, setTabCashAdv] = useState(false);
  const [tabCashCheque, setTabCashCheque] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const dateFrom = subDays(new Date(), 7);

  const [chequeImage, setChequeImage] = useState("");

  const [chkAmount, setChkAmount] = useState(0);
  const [chkpayeeName, setChkpayeeName] = useState("")
  const [invoiceSubTotal, setInvoiceSubTotal] = useState("0");


  const getcompanylist = (cid) => {
    let uid = sessionData["user_Id"];
    let tkn = sessionData["jwtToken"];
    setShowInvoices(false);

    axios
      .post(
        INVOICES_LIST + "?r=" + Math.random(),
        {
          userid: uid,
          clientid: cid,
          token: tkn,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((erpresponse) => {
        let dataResponse = erpresponse.data.response;
        let erpCompanies = erpresponse.data.companies;
        let response = dataResponse.response;

        if (dataResponse.httpcode === 200) {
          var cmpArr = [];
          sessionStorage.setItem("invoices", JSON.stringify(response));
          let invoicesListArray = [];
          let index = 0;

          /**
          for (var key in response["RECV"]) {
            let rows = response["RECV"][key];
            cmpArr[key] = rows[0].company_Name;           
            console.log("invoicesListArray:", key, "CompanyName:", rows[0].company_Name) ;
           
          }          
          if (cmpArr.length === 0) {
            console.log("ADV_CMP",response["ADV_CMP"] )
            
            cmpArr[response["ADV_CMP"].company_code] = response["ADV_CMP"].company_Name;
          }
          */
          console.log("cmP::::", erpCompanies)
          for (var key in erpCompanies) {
            console.log('103---', key, erpCompanies[key]);
            cmpArr[key] = erpCompanies[key];
          }
          
          setMycompanylist(cmpArr);
        } else {
          setShowError(true);
          setError("ErrorCode: " + response.httpcode);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getBankList = async () => {

    const requestOptions = {
      method: "POST", 
      'Content-Type': 'multipart/form-data',
      body: JSON.stringify({ userid: userid, token: token })
    };
    const response = await fetch(GET_BANK_LIST, requestOptions);
    const data = await response.json();
    
    const responseData = data.response.response
    let bankArray = []
    for(var index in responseData) {
      let bank = responseData[index];
      // console.log(bank.acc_code, bank.acc_name);
      bankArray[index]= bank;
    }

    setBankList(bankArray);
  }

  const getDepostBankList = async () => {

    const requestOptions = {
      method: "POST", 
      'Content-Type': 'multipart/form-data',
      body: JSON.stringify({ userid: userid, token: token })
    };
    const response = await fetch(GET_DEPOSIT_BANK_LIST, requestOptions);
    const data = await response.json();
    
    const responseData = data.response.response
    let bankArray = []
    for(var index in responseData) {
      let bank = responseData[index];
       //console.log(bank.acc_code, bank.acc_name);
      bankArray[index]= bank;
    }

    setDepositBankList(bankArray);
  }

  const getCompanyLicenses = (selected_company) => {
    var invoices = JSON.parse(sessionStorage.getItem("invoices"));
    $(".cashvalue").val("");
    var cmpInvoices = [];
    for (var key in invoices["RECV"]) {
      if (selected_company === key) {
        let rows = invoices["RECV"][key];
        cmpInvoices = rows;
      }
    }

    setShowInvoices(true);
    setInvoicesList(cmpInvoices);
  };

  /******* */
  const dataBox = (event) => {
    let sel_company = event.target.value;

    setCompanydd(sel_company);

    /**
     * If no compnay selected
     */
    setAndUpdateInvoiceSection(sel_company);

    getCompanyLicenses(sel_company);
  };

  const setAndUpdateInvoiceSection = (sel_company) => {
    if (sel_company === "") {
      setPaymentmode("CASH");
      setTabCashRecv(false);
      setTabCashCheque(false);
      $("#signature-tab").hide();
      return;
    }


    setTabCashRecv(true);
  };

  /******* */
  const invoicePaymentmode = (event) => {
    const newPaymentmode = event.target.value;
    setPaymentmode(newPaymentmode);
    updateSumforCheque( );
    if (newPaymentmode === "CHEQUE") {
      setTabCashCheque(true);
      setIsDisabled(false);
      
    } else if (newPaymentmode === "CASH") {
      setTabCashCheque(false);
      setIsDisabled(true);
    }

    setAndUpdateInvoiceSection(companydd);
    nextProcess();
  };
  /******* */
  const setScreenView = (paymentaction) => {
    setPaymentaction(paymentaction);
  };
  /******* */

  const handleDecimalInput = (event) => {
    var val = event.target.value;
    var re = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/g;
    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?|[0-9]+)/g;
    if (re.test(val)) {
    } else {
      val = re1.exec(val);
      if (val) {
        event.target.value = val[0];
      } else {
        event.target.value = "";
      }
    }

    // var bal = $(this).attr('due-amount') - this.value;
    // $('#due-amt-'+$(this).attr('rowid')).html(bal);
  };

 
  $(document).on("keyup", ".decimalInput", function () {
    var val = this.value;
    var re = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/g;
    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?|[0-9]+)/g;
    if (re.test(val)) {
    } else {
      val = re1.exec(val);
      if (val) {
        this.value = val[0];
      } else {
        this.value = "";
      }
    }

    let curVal = parseFloat(this.value);
    let dueVal = parseFloat($(this).attr("due-amount"));
    
    // dueVal = isNaN(dueVal) ? 0 : dueVal;
    curVal = isNaN(curVal) ? 0 : curVal;

    
    if (( curVal > dueVal || curVal < 0 ) && dueVal !== "isAdvance") {
      this.value = "";
      Swal.fire("You cant't enter zero value or more than invoice amount!  ");
    }
    var bal = $(this).attr("due-amount") - this.value;
    $("#" + $(this).attr("rowid")).html(bal);

    nextProcess();
  });

  function nextProcess() {
    var $nonempty = $(".cashvalue").filter(function () {
      return this.value !== "";
    });

    if ($nonempty.length === 0) {
      $("#btn-payment").attr("disabled", "disabled");      
    } else {
      $("#btn-payment").removeAttr("disabled");      
    }

    updateSumforCheque( );
  }

  function updateSumforCheque( ) {
    
    var sum = 0;
    $(".cashvalue").each(function () {
      if (this.value > 0 && this.value !== "") {
        sum += parseFloat(this.value);
      }      
    });

    let advPayment = $('#advance-payment').val() ;
    advPayment = ( advPayment === "" || advPayment === null) ? 0 : advPayment;
    
    setChkAmount(sum);
    sessionStorage.setItem('chkAmount', sum);
    sessionStorage.setItem('paymentmode', paymentmode);
    $('#processPaymentMode').html($('#payment_mode').val());
    $('#chkPaymentAmount').html(sum);
    console.log("SUM", sum)
    $("#chequeAmount").val(sum);
    var invoicePayment = sum - advPayment;
    setInvoiceSubTotal(invoicePayment);
  }

  const handleCheckPayeename = (event) => {
    var inputText = event.target.value;
    let chkname = inputText.toUpperCase();
    setChkpayeeName(chkname)
  }

  $(document).on("keyup", ".onlydigits", function () {
    var inputValue = $(this).val();
    var alphanumericRegex = /^[0-9]*$/;
    if (!alphanumericRegex.test(inputValue)) {
      // If the input contains non-alphanumeric characters, remove them
      $(this).val(inputValue.replace(/[^0-9]/g, ""));
    }
  });

  const validatePaymentInfo = () => {
    console.log(paymentmode);

    if (paymentmode === "CHEQUE") {
      var deposit_bankcode = $.trim($("#deposit_bankcode").val());
      var bankcode = $.trim($("#bankcode").val());
      var payeeName = $.trim($("#payeeName").val());
      var chequeNo = $.trim($("#chequeNo").val());
      var chequeDate = $.trim($("#chequeDate").val());
      var chequeAmount = $.trim($("#chequeAmount").val());
      var chequefile = $.trim($("#chequefile").val());

      if (deposit_bankcode === "") {
        Swal.fire({
          text: "Please choose Company/Deposit Bank",
         
        });
        return false;
      }
      
      if (bankcode === "") {
        Swal.fire({
          text: "Please choose the bank",
         
        });
        return false;
      }
      
      if (payeeName === "") {
        Swal.fire({
          text: "Please enter Payee name",
          didClose: () => {
            setTimeout(() => $("#payeeName").focus(), 110);
          },
        });
        return false;
      }

      if (chequeNo === "") {
        Swal.fire({
          text: "Please enter cheque no",
          didClose: () => {
            setTimeout(() => $("#chequeNo").focus(), 110);
          },
        });
        return false;
      }

      if (chequeDate === "") {
        Swal.fire({
          text: "Please enter cheque date",
          didClose: () => {
            setTimeout(() => $("#chequeDate").focus(), 110);
          },
        });
        return false;
      }

      if (chequeAmount === "") {
        Swal.fire({
          text: "Please enter cheque amount",
          didClose: () => {
            setTimeout(() => $("#chequeAmount").focus(), 110);
          },
        });
        return false;
      }
      if (chequefile === "") {
        Swal.fire({
          text: "Please attach the cheque image",
         
        });
        return false;
      }
    }

    let remarks = $('#remarks').val().trim();

    if (remarks.length < 8 ) {
      Swal.fire({
        title: "Error",
        "text": "Enter minimum 8 characters as remarks"

      });
      return false;
    }

    $("#payment-tab").hide();
    $("#complete-tab").hide();
    $("#signature-tab").show();
  };




  const  handleFileInputChange = (e) => {
      console.log(e.target.files[0]);
      if (e.target.files[0] === "") return "";
  
      let file = e.target.files[0];
      let reader = new FileReader();

      if (file === "" || file === undefined) {
        return false;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader.result);
        setChequeImage(reader.result);
        sessionStorage.setItem('chkimg', reader.result);
        
      };  
    };

    function handleTakePhoto (dataUri) {
      // Do stuff with the photo...
      console.log('takePhoto', dataUri);
    }

  useEffect(() => {
    let useredData = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(useredData);
    setClientid(routeParams.id);
    setUserid(useredData["user_Id"]);
    setToken(useredData["jwtToken"]);
    
    setPaymentaction("RECV"); /*** Set as Default ***/
    setPaymentmode("CASH");
    setTabCashCheque(false);
    getcompanylist(routeParams.id);

    getBankList();
    getDepostBankList();
    /***client_info */
    setClientFullName(selectedClient["acc_name"]);
    setChkpayeeName(selectedClient["acc_name"]);
    setClientAccCode(selectedClient["acc_code"]);
    setClientSubTitle(selectedClient["notes"]);
  }, []);

  return (
    <>
      <div className="col-md-12 ">
        <div className="text-center">
          {/* <h3 className="text-center">Payment</h3> */}
          {clientFullName}
          <br />
          <b>A/C Code: </b> {clientAccCode}
          <br />
          <b>Notes: </b> {clientSubTitle}
        </div>
        <hr />
        <div className="row" style={{ display: "none" }}>
          <div className="col-md-6">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                checked={paymentaction === "RECV"}
                onClick={() => setScreenView("RECV")}
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Receivable
              </label>
            </div>
            <div style={{ display: "none" }} className="form-check">
              <input
                className="form-check-input"
                type="radio"
                checked={false}
                onClick={() => setScreenView("RECV")}
                name="flexRadioDefault"
                id="flexRadioDefault2"
              />
              <label className="form-check-label" for="flexRadioDefault2">
                Advance
              </label>
            </div>
          </div>
          <button
                    onClick={() => navigate("/list")}
                    className="btn btn-sm btn-default"
                  >
                    <i className="fa fa-reply-all"></i> Back
                  </button>
          <hr />
        </div>

        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="border p-3 rounded">
                <h6 className="mb-0 text-uppercase">Company</h6>
                <hr />
                {/* <Camera
      onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
    /> */}
                <div className="col">
                  
                  <select
                    className="form-select mb-3"
                    value={companydd}
                    id="company"
                    onChange={dataBox}
                  >
                    <option value="">Choose Company</option>

                    {mycompanylist.map((companylist, idx) => (
                      <option value={idx}>[{idx}]&nbsp;{companylist}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        {tabCashRecv && (
          <div className="card">
            <div className="card-body">
              <div className="border p-3 rounded">
                <h6 className="mb-0 text-uppercase">Bills & Payment</h6>
                <hr />
                <div className="row" id="data-container">
                  <div className="table-responsive" id="payment-container">
                    <div className="row">
                      <div className="col-md-12">
                        <div>
                          <table
                            className="table table-striped table-bordered"
                            id="tab-cash-recv"
                          >
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th>Invoice Title</th>
                                <th>Currency</th>
                                <th>Due Amount</th>
                                <th>Amount</th>
                                <th>Balance</th>
                              </tr>
                            </thead>
                            <tbody id="invoices-body">
                              {invoicesList.map((invoice, idx) => (
                                  invoice.amt > 0 ? (
                                <tr>
                                  <td>{idx + 1} </td>
                                  <td> {invoice.bill_no} </td>
                                  <td> {invoice.cur_code} </td>
                                  <td>                                  
                                    {invoice.amt >= 0 ? invoice.amt : 0}{" "}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      maxLength={15}
                                      //   onblur={nextProcess}
                                      rowid={
                                        "due-amt-" + companydd + "_" + idx + 1
                                      }
                                      due-amount={
                                        invoice.amt >= 0 ? invoice.amt : 0
                                      }
                                      disabled={invoice.amt <= 0 ? true : false}
                                      name="invoice[]"
                                      size={20}
                                      onBlur={updateSumforCheque}
                                      //   value=""
                                      //   onChange={handleDecimalInput}
                                      placeholder="00.000"
                                      id={"invoice-" + companydd + "_" + idx + 1}
                                      className="cashvalue form-control decimalInput"
                                    />
                                  </td>
                                  <td>
                                    <span  id={"due-amt-" + companydd + "_" + idx + 1} >
                                      {invoice.amt >= 0 ? invoice.amt : 0}
                                    </span>
                                  </td>
                                </tr>
                                ): ("")
                              ))}
                               <tr style={{ "background": "#ccccc" }}>
                                  <td></td>
                                  <td colSpan={3}> <h5>Invoice Total</h5> </td>
                                  
                                  
                                  <td colSpan={2}>
                                    <h5 id="total-invoice">{invoiceSubTotal}</h5>
                                  </td>
                                  
                                </tr>
                            </tbody>
                          </table>
                          <table
                            className="table table-striped table-bordered advance"
                            id="tab-cash-adv"
                          >
                            <tbody>
                              <tr>
                                <td>Advance Payment</td>
                                <td>
                                  <input
                                    type="text"
                                    name="advance-payment"
                                    id="advance-payment"
                                    due-amount={"isAdvance"}
                                    maxlength="15"
                                    className="form-control decimalInput cashvalue"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="row">
                            <div className="card">
                              <div className="card-body p-3">
                                <div className="border p-3 rounded">
                                  <h6 className="mb-0 text-uppercase">
                                    Payment Mode
                                  </h6>
                                  <hr />
                                  <div className="col">
                                    <select
                                      className="form-select mb-3"
                                      id="payment_mode"
                                      name="payment_mode"
                                      value={paymentmode}
                                      onChange={invoicePaymentmode}
                                    >
                                      <option value="CASH">Cash</option>
                                      <option value="CHEQUE">Cheque</option>
                                    </select>
                                  </div>
                                  <div className="col">                                  
                                  Select Company/Deposit Bank                                              
                                              <select
                                                className="form-select mb-3"
                                                disabled={isDisabled ? true : false}
                                                id="deposit_bankcode"                                                
                                              >
                                                <option value="">Company/Deposit Bank</option>

                                                {depostiBankList.map((bank,  bankname) => (
                                                  <option value={bank.acc_code}>{bank.acc_name}</option>
                                                ))}
                                              </select>
                                              
                                  </div>
                                </div>

                                {tabCashCheque && (
                                  
                                      <div className="border p-3 rounded">
                                        <h6 className="mb-0 text-uppercase">
                                          Cheque Information
                                        </h6>
                                        <hr />
                                        <table
                                          className="table table-striped table-bordered"
                                          id="tab-cash-cheque"
                                        >
                                          <thead></thead>
                                          <tbody>
                                            <tr>
                                              <td>Select Customer Bank</td>
                                              <td>
                                              <select
                                                className="form-select mb-3"
                                                
                                                id="bankcode"                                                
                                              >
                                                <option value="">Customer Bank</option>

                                                {bankList.map((bank,  bankname) => (
                                                  <option value={bank.bank_code}>{bank.bank_name}</option>
                                                ))}
                                              </select>
                                              </td>
                                            </tr>
                                            
                                            <tr>
                                              <td>Received From </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  name="payeename"
                                                  id="payeeName"
                                                  maxlength="50"
                                                  
                                                  onChange={handleCheckPayeename}
                                                  value={chkpayeeName}
                                                  className="form-control "
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Cheque no.#</td>
                                              <td>
                                                <input
                                                  type="text"
                                                  maxlength="20"
                                                  name="chequeNo"
                                                  id="chequeNo"
                                                  className="form-control onlydigits"
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Cheque date.#</td>

                                              <td>
                                                <DatePicker
                                                  className="form-control "
                                                  dateFormat="dd/MM/yyyy"
                                                  selected={startDate}
                                                  minDate={new Date(new Date().getFullYear(), new Date().getMonth()-5, 1)}
                                                  name="chequeDate"
                                                  id="chequeDate"
                                                  onChange={(date) =>
                                                    setStartDate(date)
                                                  }
                                                />
                                                
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Cheque amount.#</td>
                                              <td>
                                                <input
                                                  readOnly
                                                  type="text"
                                                  name="chequeAmount"
                                                  id="chequeAmount"
                                                  value={chkAmount}
                                                  maxlength="15"
                                                  className="form-control decimalInput"
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Cheque #</td>
                                              <td>
                                                <input
                                                  type="file"
                                                  name="file"
                                                  id="chequefile"
                                                  className="form-control"
                                                  size="30"                                                  
                                                  accept=".jpef, .png, .jpg, .JPEF, .JPEG, .PNG, .JPG"
                                                  onChange={handleFileInputChange}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                     
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="remarks">Remarks/Particular</label>
                    <textarea className="form-control" id="remarks"></textarea>
                  </div>
                  <hr />
                  <button
                    onClick={() => validatePaymentInfo()}
                    id="btn-payment"
                    className="btn btn-primary float-right"
                  >
                    Processed <span>&rarr;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!tabCashRecv && (
          <div className="alert border-0 bg-warning alert-dismissible fade show py-2">
            <div className="d-flex align-items-center">
              <div className="fs-3 text-dark">
                <i className="bi bi-exclamation-triangle-fill"></i>
              </div>
              <div className="ms-3">
                <div className="text-dark">
                  Choose Company to process the payment!
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Paymentinfo;
