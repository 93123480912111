import * as React from "react";
import {useNavigate} from 'react-router-dom'
import { useEffect, useState } from "react";

const Header = (  ) => {
    
    const[displanName, setDisplayName] = useState("")
    const[userfullname, setUserfullname] = useState("")
    const[email, setEmail] = useState("")
    const navigate = useNavigate();
    const [userData,setUserData] = useState("");
    
    
    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");        
        if (!loggedIn) {
          navigate("/login");
        }

        let useredData = JSON.parse(sessionStorage.getItem("userData"));
        if (useredData) {
        setDisplayName(useredData.user_Id);
        setUserfullname(useredData.ename)
        setEmail(useredData.email)
        }
        
        
      }, [navigate]);
      
  return (
    <div className="wrapper">
      <header className="top-header">
        <nav className="navbar navbar-expand ">
          <div className="mobile-toggle-icon d-xl-none">
            <i className="bi bi-list"></i>
          </div>
          <div className="top-navbar d-none d-xl-block">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item ">
                <a className="nav-link" href="/dashboard">
                  Dashboard
                </a>
              </li>
            </ul>
          </div>
          <div className=" d-xl-none ms-auto"></div>
          <form className="searchbar d-none d-xl-flex "></form>
          <div className="top-navbar-right">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item dropdown dropdown-large">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <div className="user-setting d-flex align-items-center gap-1">
                    
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAEwUlEQVR4Xu2aaahNaxjHj4srInSRIVJkuDJThJIk8oGk8OGWIrcbIVORechQpsIH4xdJJJTMNxlSSBkSmT5Q5qkImf+/rKPt7L32etdaz9pn733OU//OPms/7/P+3/9+17ue93lXlZIKblUq+PhLKgWonAEVXIHyuAWaSvNBQiehoaf/c/29JhwXHuXyN8mlAL00sIXe4P36/a7vjwqLhAu5ECIXAtTUQBYIM4SqjoP6Jr+twnThnWObSG5JC8AU/1/oGIldSclVtRsovIjYPrBZkgLEHXwp+Zv6MEB4EjiaCA5JCfCH98v3j8ApU5Nzukisr0bxfoVJSoCZ6mGVMVnWgzXGMRPJBFuK5C2hhjHZj4rXVnhgGTeJGbBeBCdbkkyJtVafp1nGthagvvcL1bYkmRLrrT63EN5YxbcWYLyIbbEi5xNnnK5vt+rDWoBdIjbGipxPnJ26/o9VH5YCEIs8vrEVOZ84j3W9mUDaHNssBWjiCRCblEMARH7q4BfoYilAZ/V2JbBHGwd2ktctQlkKQM5+woKUQwz6Yo8R2ywFGCY2B2IzcgswXG4H3Vyze1kK0FddnbUg5RCjj3zOO/gFulgKQJpKCpwLa6NO7lh0ZClAXRF6LVjGzDRGiiX1BLLC2GZNlrpe1OKH62B40nR1dQ7ysxZgnTqcEtRpzO/ZErM1NjFrAYaI1WETZv5BBuurY1Z9WAtAJeiG0M6KYJk4t/V/e4F1wMSsBYDUf8ImE3bpQf7Vpc2WsZMQgDI4aWorS6KKxWOPFJjKkJklIQDkegskRa7nAEEDYsr392IG+Yb6PikBILFYmBeKjb8zsThcMbckBSA2j6ypMVlTB+SxZ7L/L8slSQFK+1qqD7MFnhBhjGm/TJgfplFY31wIACfWhG0CjzAXuysn6ounXZzj+ORKADhyTkC9cKzAzrHsAsmpDwvnDmG38CnOwFzb5lKAVE6UzTsIjbyLz/SXBCrRk+BMopSXAK4/UOJ+lgJUF1tOcUlWWMA41T0lfIg5CmbLKKGbUE24LxwR2HnGNgsBOA0iReU4jMpwqr3UPyuFjcL7kGxryX+SMEv4K0Pbk7q2QohVG4wjAIsag54jUKDIZrzgwMIGLgl+CxwxewqjPWQaeNl+2BmSJ7CGhLaoAvRTTxxPtQ7d48/BM3154YEKEsYsotbP7fNnxJjkG8yIz2HahxWARxfJCef/YRObMLyi+lIoHSlweuRkYQSg5rdH4BW3fDaO54YKToc0rgI0UEBeX+uezyNP4catReXoYhBfFwHqKMgZoUtQsDz7/pX4cH6QtVQfJAD3/CFPzTwbnxMdcoYeQulim9YoSIC5arHEqav8ddonaiyMGS2bAJz28swmwyt0G6EB7M80iGwCkGGR2haD3dMg/hbSEjA/AUh0WPiKybgNuB1+Mz8BNshrYjGNXmPh5a0JrgLslaPvwlGgwpDEsat0mgG85kq6W0zG0yytvuh3C3D6elkIekwWikCU2zi1pkbhNANwWi2YvpZajmotV99s29Ms2y/Mbm+hQEmbSkwh2heRZuqDjOcKLlO8uRpToODEl31BIRhvj7AHoADzMBthFwEKYcCROVYKEFm6ImlYOQOK5IeMPIwfb9+iQe3CTwUAAAAASUVORK5CYII="
                      className="user-img"
                      alt=""
                    />
                    <div className="user-name d-none d-sm-block">{displanName}</div>
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex align-items-center">
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAEwUlEQVR4Xu2aaahNaxjHj4srInSRIVJkuDJThJIk8oGk8OGWIrcbIVORechQpsIH4xdJJJTMNxlSSBkSmT5Q5qkImf+/rKPt7L32etdaz9pn733OU//OPms/7/P+3/9+17ue93lXlZIKblUq+PhLKgWonAEVXIHyuAWaSvNBQiehoaf/c/29JhwXHuXyN8mlAL00sIXe4P36/a7vjwqLhAu5ECIXAtTUQBYIM4SqjoP6Jr+twnThnWObSG5JC8AU/1/oGIldSclVtRsovIjYPrBZkgLEHXwp+Zv6MEB4EjiaCA5JCfCH98v3j8ApU5Nzukisr0bxfoVJSoCZ6mGVMVnWgzXGMRPJBFuK5C2hhjHZj4rXVnhgGTeJGbBeBCdbkkyJtVafp1nGthagvvcL1bYkmRLrrT63EN5YxbcWYLyIbbEi5xNnnK5vt+rDWoBdIjbGipxPnJ26/o9VH5YCEIs8vrEVOZ84j3W9mUDaHNssBWjiCRCblEMARH7q4BfoYilAZ/V2JbBHGwd2ktctQlkKQM5+woKUQwz6Yo8R2ywFGCY2B2IzcgswXG4H3Vyze1kK0FddnbUg5RCjj3zOO/gFulgKQJpKCpwLa6NO7lh0ZClAXRF6LVjGzDRGiiX1BLLC2GZNlrpe1OKH62B40nR1dQ7ysxZgnTqcEtRpzO/ZErM1NjFrAYaI1WETZv5BBuurY1Z9WAtAJeiG0M6KYJk4t/V/e4F1wMSsBYDUf8ImE3bpQf7Vpc2WsZMQgDI4aWorS6KKxWOPFJjKkJklIQDkegskRa7nAEEDYsr392IG+Yb6PikBILFYmBeKjb8zsThcMbckBSA2j6ypMVlTB+SxZ7L/L8slSQFK+1qqD7MFnhBhjGm/TJgfplFY31wIACfWhG0CjzAXuysn6ounXZzj+ORKADhyTkC9cKzAzrHsAsmpDwvnDmG38CnOwFzb5lKAVE6UzTsIjbyLz/SXBCrRk+BMopSXAK4/UOJ+lgJUF1tOcUlWWMA41T0lfIg5CmbLKKGbUE24LxwR2HnGNgsBOA0iReU4jMpwqr3UPyuFjcL7kGxryX+SMEv4K0Pbk7q2QohVG4wjAIsag54jUKDIZrzgwMIGLgl+CxwxewqjPWQaeNl+2BmSJ7CGhLaoAvRTTxxPtQ7d48/BM3154YEKEsYsotbP7fNnxJjkG8yIz2HahxWARxfJCef/YRObMLyi+lIoHSlweuRkYQSg5rdH4BW3fDaO54YKToc0rgI0UEBeX+uezyNP4catReXoYhBfFwHqKMgZoUtQsDz7/pX4cH6QtVQfJAD3/CFPzTwbnxMdcoYeQulim9YoSIC5arHEqav8ddonaiyMGS2bAJz28swmwyt0G6EB7M80iGwCkGGR2haD3dMg/hbSEjA/AUh0WPiKybgNuB1+Mz8BNshrYjGNXmPh5a0JrgLslaPvwlGgwpDEsat0mgG85kq6W0zG0yytvuh3C3D6elkIekwWikCU2zi1pkbhNANwWi2YvpZajmotV99s29Ms2y/Mbm+hQEmbSkwh2heRZuqDjOcKLlO8uRpToODEl31BIRhvj7AHoADzMBthFwEKYcCROVYKEFm6ImlYOQOK5IeMPIwfb9+iQe3CTwUAAAAASUVORK5CYII="
                          alt=""
                          className="rounded-circle"
                          width="60"
                          height="60"
                        />
                        <div className="ms-3">
                          <h6 className="mb-0 dropdown-user-name">{userfullname}</h6>
                          <small className="mb-0 dropdown-user-designation text-secondary">
                            <b>Email: </b>{email}
                          </small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="/logout">
                      <div className="d-flex align-items-center">
                        <div className="setting-icon">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <div className="setting-text ms-3">
                          <span>Logout</span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      {/* Sidebar */}
      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <div>
            <img
              src="/assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <h4 className="logo-text">AlMarwan</h4>
          </div>
          <div className="toggle-icon ms-auto">
            <i className="bi bi-chevron-double-left"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu">
          {/* <li>
            <a href="/dashboard" className="">
              <div className="parent-icon">
                <i className="bi bi-house-door"></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </a>
          </li> */}
          <li>
            <a href="/list" className="">
              <div className="parent-icon">
                <i className="bi bi-grid"></i>
              </div>
              <div className="menu-title">Customers</div>
            </a>
          </li>
          <li>
            <a href="/userhistory" >
              <div className="parent-icon">
                <i className="bi bi-file-earmark-code"></i>
              </div>
              <div className="menu-title">History</div>
            </a>
          </li>
          <li>
            <a href="/registration" >
              <div className="parent-icon">
                <i className="bi bi-file-earmark-code"></i>
              </div>
              <div className="menu-title">Registration</div>
            </a>
          </li>

          <li>
            <a href="/logout">
              <div className="parent-icon">
                <i className="bi bi-box-arrow-right"></i>
              </div>
              <div className="menu-title">Logout</div>
            </a>
          </li>
        </ul>
      </aside>
      {/* Sidebar */}
    </div>
  );
}

export default Header;
