import React from 'react';

import Login from './Components/Login';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import Logout from './Components/Logout';
import ClientList from './Components/ClientList';
import Paymentscreen from './Components/Paymentscreen';
import Invoiceprint from './Components/Invoiceprint';
import { HistoryScreen } from './Components/HistoryScreen';
import { Printpage } from './Components/Printpage';
import { CustomerRegistration } from './Components/CustomerRegistration';

// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



function App() {
  
  return (
    <div className="MainApp">
      <BrowserRouter >
        <Routes > 
            <Route index element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/dashboard' element={<ClientList />} />
            <Route exact path='/list' element={<ClientList />} />
            <Route path='/userhistory' element={<HistoryScreen />} />
            <Route path='/printhistory' element={<Printpage />} />
            <Route path="/payment/:id" element={<Paymentscreen />} />
            <Route path="/registration" element={<CustomerRegistration />} />
            <Route path="/completed" element={<Invoiceprint />} />
            <Route path='/logout' element={<Logout />} />

        </Routes>
      </BrowserRouter>
      
        
    </div>
  );
}

export default App;
