import React, { useState, useEffect } from "react";
import Header from "./Header";
import { ToWords } from "to-words";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { RECEIPT_PAYMENT_TITLE } from "../constants";
import { TailSpin, Bars } from "react-loader-spinner";
import logo from '../images/logo-login.png'
const Invoiceprint = () => {
  const [loading, setLoading] = useState(false);
  let selectedClient = JSON.parse(sessionStorage.getItem("selectedClient"));
  let paymentResponse = JSON.parse(sessionStorage.getItem("invoices"));
  const toWords = new ToWords({
    localeCode: "en-AE",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });

  const [clientFullName, setClientFullName] = useState("");
  const [clientAccCode, setClientAccCode] = useState("");
  const [clientSubTitle, setClientSubTitle] = useState("");
  const [customerSign, setCustomerSign] = useState("");

  const [companyName, setComopanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPOBox, setCompanyPOBox] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyFax, setCompanyFax] = useState("");

  const [reciptType, setReciptType] = useState("")
  const [reciptCurrency, setReciptCurrency] = useState("")
  const [receiptNo, setReceiptNo] = useState("");
  const [receiptDated, setReceiptDated] = useState("");
  const [receiptCreditToAcc, setReceiptCreditToAcc] = useState("");
  const [receiptName, setReceiptName] = useState("");
  const [receiptFrom, setReceiptFrom] = useState("");
  const [receiptAEDWords, setReceiptAEDWords] = useState("");
  const [remarksParticular, setRemarksParticular] = useState("");
  const [receiptAmount, setReceiptAmount] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [chequeNo, setCheuqeNo] = useState("")
  
  const printRecipt = () => {
    
     let printContents = document.getElementById("printableInvoice").innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
  };

  const downloadPdfDocument = () => {
    const input = document.getElementById("printableInvoice");
    html2canvas(input)
      .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            format: "a4",
            unit: "mm",
          });
          pdf.addImage(imgData, 'JPEG', 0, 0);
          pdf.save("download.pdf");
      })
  }

  useEffect(() => {
    setLoading(true);
    let payment = paymentResponse.response['payment'];
    let outstanding = paymentResponse.response['outstanding'][0];
    console.log("paymentResponse", payment);
    console.log("outstandingResponse", outstanding['company_Name']);

    
    setClientFullName(selectedClient["acc_name"]);
    setClientAccCode(selectedClient["acc_code"]);
    setClientSubTitle(selectedClient["notes"]);

    setComopanyName(outstanding['company_Name']);
    setCompanyAddress("Industrial Area 15.");
    setCompanyPOBox("4174");
    setCompanyPhone("(+971) 6 5345 222");
    setCompanyFax("(+971) 6 534 4337");

    setReciptType(RECEIPT_PAYMENT_TITLE)
    setReciptCurrency("AED")
    setReceiptNo(payment["vr_no"]);
    let vr_date = payment["vr_date"].replace("T", " ").substring(0, 19);
    var now = new Date();

    setReceiptDated("2023-12-27 ");
    setReceiptCreditToAcc(payment['acc_code']);
    setReceiptName(selectedClient["acc_name"]);
    setCustomerSign(payment['customer_Sign'])
    setCustomerName(payment['customer_Name'])
    setRemarksParticular(payment['particulars'])
    setCheuqeNo(payment['chq_no'])

    let rcptAmt = parseFloat(payment['acc_amt']);
    setReceiptAmount(rcptAmt);
    let words = toWords.convert(rcptAmt);
    setReceiptAEDWords(words);
    setLoading(true);
  });

  return (
    <>
      <div className="row">
        <Header />
        <main className="page-content">

          <div className="card border shadow-none">
            <div className="card-header py-3">
              <div className="row align-items-center g-3">
                <div className="col-12 col-lg-6 text-md-end">
                  {/* <a href="!#" target="_blank" onClick={downloadPdfDocument} className="btn btn-sm btn-danger me-2"><i className="bi bi-file-earmark-pdf-fill"></i> Export asPDF</a> */}
                  <a
                    href="#!"
                    onClick={printRecipt}
                    className="btn btn-sm btn-secondary"
                  >
                    <i className="bi bi-printer-fill"></i> Print
                  </a>
                  
                </div>
              </div>
            </div>
            <div 
            id="printableInvoice"
            >
              <div className="card-header py-2 bg-light text-center">
                <div className="row row-cols-1 row-cols-lg-3">
                  <div className="col">
                    <div className="">
                    <img src={logo} alt="logo" />
                      <h2>{companyName} </h2>
                      <address className="m-t-5 m-b-5">
                        <strong className="text-inverse">
                          {companyAddress}
                        </strong>
                        <br />
                        Post Box: {companyPOBox}
                        <br />
                        Tel: {companyPhone}
                        <br />
                        Fax: {companyFax}
                      </address>
                    </div>
                  </div>
                  <hr />
                  <h5 className="text-inverse">{reciptType}</h5>
                </div>
              </div>
              <div className="card-body">
              <div className="text-right " style={{ marginTop: "-10px",marginBottom: "15px",float: "right",border: "1px solid #ccc",padding: "5px" }} >
                        <b>{reciptCurrency} :</b> {receiptAmount}
                    </div>
                    <div style={{content: "",  clear: "both"  }}></div>
                <div className="table-responsive">
                    
                  <table className="table table-invoice" border={1}>
                    <thead>
                    {/* <tr>
                        
                        <th colSpan={3}></th>
                        <th>{reciptCurrency}</th>
                        
                      </tr> */}
                    </thead>
                    <tbody>
                      
                      <tr>
                        <th width="200px">VR. No.</th>
                        <td className="text-left">{receiptNo}</td>

                        <th>Date:</th>
                        <td className="text-left">{receiptDated}</td>
                      </tr>
                      <tr>
                        <th>Credit To A/C No.</th>
                        <td className="text-left">{receiptCreditToAcc} </td>
                        <th>Name:</th>
                        <td className="text-left">{receiptName}</td>
                      </tr>
                      <tr>
                        <th>Cheque No.</th>
                        <td className="text-left">{chequeNo} </td>
                        
                      </tr>
                      <tr>
                        <th>Received From.</th>
                        <td colSpan={4} className="text-left">
                          {clientFullName}
                        </td>
                      </tr>
                      <tr>
                        <th>Amount in words.</th>
                        <td colSpan={4} className="text-left">
                          {receiptAEDWords}
                        </td>
                      </tr>
                      <tr>
                        <th>Remarks/Particular.</th>
                        <td colSpan={4} className="text-left">
                          {remarksParticular}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>&nbsp; </td>
                        <td className="text-center">
                          {/* <h3>Signature</h3><br /> */}
                          
                        <img src={customerSign} style={{ width: "80px" }} alt="Signature" />
                        <hr />
                        {customerName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* <div className="row bg-light align-items-right m-0">
                  <div className="col col-auto me-auto p-4"></div>
                  <div className="col bg-dark col-auto p-4 align-items-right">
                    <p className="mb-0 text-white">Amount</p>
                    <h4 className="mb-0 text-white">{receiptAmount}</h4>
                  </div>
                </div> */}
              </div>

              <div className="card-footer py-3">
                <p className="text-center mb-2">THANK YOU FOR YOUR BUSINESS</p>
                <p className="text-center d-flex align-items-center gap-3 justify-content-center mb-0">
                  “This document is computer generated and does not require the
                  Registrar’s signature or the Company’s stamp in order to be
                  considered valid”.
                  {/* <span className="">
                  <i className="bi bi-globe"></i> www.almarwangroup.com
                </span>
                <span className="">
                  <i className="bi bi-telephone-fill"></i> T:+1971.6.5345222
                </span>
                <span className="">
                  <i className="bi bi-envelope-fill"></i> info@almarwangroup.com
                </span> */}
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Invoiceprint;
